import React from 'react';
import './home-video.css';
import HostVideo3 from './video-box1';
import { Link } from 'gatsby';
import AppButton from '../CustomComponents/app-button';

function HomeVideoOne() {
    return (
        <>

            <div className="video-section ">
                <div className="video-wrapper container">
                    <div className="video-block">
                        <HostVideo3 videoSrcURL={undefined}
                            videoTitle={undefined}
                            className={'video1-wrapper  animate'} />
                        <div className="bg-1"></div>
                        <div className="bg-2"></div>
                    </div>
                    <div className="text-block">
                        <h1 className="bold video-title animate">The Future of Customer Support: An In-Depth Conversation with a CX Expert</h1>
                        {/* <p className="video-des animate">Read a condensed version here → <Link to={"/blog/challenges-and-future-trends-in-customer-service"}>The Future Of Support</Link><br />
                            Or choose from our curated clips below.</p> */}
                    </div>
                </div>

            </div>
            <div className='btn-box'>
                <AppButton variant={'variant-teal btn-video animate'} text={'Ready to Supercharge Your Agents?'} linkTo={'/get-started'} />
            </div>

        </>

    );
}

export default HomeVideoOne;
