import React from 'react';
import { howItworksType } from '../../../templates/use-cases';
import './how-it-works-3.css';
import AppButton from '../../CustomComponents/app-button';

// Temporary image component
const AppImage = ({ image }: { image: string }) => {
    return <img src={image} alt="" style={{ width: '100%' }} loading="lazy" />;
};

function HowItWorksOne({ howItWorksImages }: { howItWorksImages?: howItworksType }) {
    const buttonProps = (labelText?: string) => {
        return {
            style: { padding: "0.5em 0.75em", marginTop: "0.75em", fontWeight: "normal" },
            variant: 'variant-teal',
            text: labelText ?? 'Learn More'
        }
    }
    return (
        <div className="howitworks3">
            <div className="parent-wrapper">
                <div className="content-container">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/946777412?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom title">AI Impacts on Customer Support</h2>
                        <p className="hw-des">
                            Generative AI is revolutionizing customer support by providing agents with immediate access to critical information. This cutting-edge technology enhances the customer experience by equipping support teams with the tools needed to resolve issues swiftly and effectively, ensuring high-quality service and support continuity.
                        </p>
                        {/* <AppButton {...buttonProps()} linkTo='/blog/benefits-knowledge-management-customer-service' /> */}
                    </div>
                </div>

                <div className="content-container isreversed">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/946777632?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom title">Navigating the Knowledge Explosion: The Evolving Role of Customer Support Systems</h2>
                        <p className="hw-des">
                            As knowledge sources grow and information systems expand, customer support needs to evolve. How do teams access and merge diverse knowledge repositories in real time? Developments in AI are transforming how support professionals provide timely and effective service to customers in a rapidly changing environment.
                        </p>
                        {/* <AppButton {...buttonProps()} linkTo='/technology/gpt-answer-engine/' /> */}
                    </div>
                </div>
                <div className="content-container">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/946777552?h=f7397fcec7"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom title">Generative AI: Enhancing Customer Feedback Loops for Superior Product Development</h2>
                        <p className="hw-des">
                            AI Technologies are key in gathering real-time customer feedback across multiple channels and sharing them cross functionally. This integration boosts the effectiveness of frontline agents in resolving issues and enriches product development with valuable insights, fostering continuous improvement and customer satisfaction.
                        </p>
                        {/* <AppButton {...buttonProps()} linkTo='/improved-resolution-time/' /> */}
                    </div>
                </div>
                <div className="content-container isreversed">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/946777696?h=c5674a4a00"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom title">Stretching the Dollar with Generative AI</h2>
                        <p className="hw-des">
                            By quantifying the return on investment, AI tools help shift the perception of customer support from a cost center to a revenue-generating asset. This shift in operational efficiency and increase in customer satisfaction will increase CFO satisfaction.
                        </p>
                        {/* <AppButton {...buttonProps('Download')} linkTo='https://drive.google.com/file/d/1o3AD4DEZDraQF3DzzoBL6yL7Y4S7BUef/view?usp=drive_link' /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorksOne;
