import React from 'react';
import AppLayout from '../../components/AppLayout';
import '../../styles/index.css';
import { imageDataType } from './prevent-escalations';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import HomeVideoOne from '../../components/video-section2';
// import HowItWorks from '../components/video-section2/how-it-works-3';
import HowItWorksOne from '../../components/video-section2/how-it-work-3';

function FiresideChatFutureCustomerSupport({ data }: { data: imageDataType }) {
    const pageData = {
        seoData: {
            title: " Fireside Chat; Future of Support",
            canonicalLink: "https://www.aptedge.io/technology",
            metaDescription: {
                metaDescription: `Check out the latest interview of Aakrit Prasad (Co-Founder & CEO) and Gerald Hastie, Customer Support Leader, a Strategic Advisor to Aptedge on "Reframing Support"`,
            },
            openGraphImage: {
                file: {
                    url: "",
                },
            },
        },
    }

    return (
        <>
            <AppLayout seoData={pageData.seoData}>
                <HomeVideoOne />



                <HowItWorksOne />

                <CtaBlock ctaTitle={undefined} />
            </AppLayout>
        </>
    );
}

export default FiresideChatFutureCustomerSupport;
